import React, { Component } from 'react';

class Navbar extends Component {
  constructor(props){
    super(props);
    this.state = {
      mobileNavActive: false,
      mobileWindow: false
    }
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  toggleMobileMenu(){
    this.setState(prevState => ({
      mobileNavActive: !this.state.mobileNavActive
    }))
  }

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    if(window.innerWidth > 600) {
      this.setState({ mobileWindow: false});
    } else {
      this.setState({ mobileWindow: true});
    }
  }

  /**
  * Add event listener
  */
 componentDidMount() {
   this.updateDimensions();
   window.addEventListener("resize", this.updateDimensions.bind(this));
 }

 /**
  * Remove event listener
  */
 componentWillUnmount() {
   window.removeEventListener("resize", this.updateDimensions.bind(this));
 }

  render() {
    let navUlStyles;
    if(!this.state.mobileWindow){
      navUlStyles = {
        display: 'inline-block'
      }
    } else if (this.state.mobileWindow && this.state.mobileNavActive){
      navUlStyles = {
        display: 'block'
      }
    } else if (this.state.mobileWindow && !this.state.mobileNavActive){
      navUlStyles = {
        display: 'none'
      }
    }

    return (
      <div className="nav-container">
        <span>[</span>
        <ul style={ navUlStyles } >
          <li onClick={this.props.top} >Top</li>
          <li onClick={this.props.density} >Technology</li>
          <li onClick={this.props.team} >Team</li>
          <li onClick={this.props.contact} >Contact</li>
        </ul>
        <span>]</span>
        <i className="material-icons" onClick={ this.toggleMobileMenu }>menu</i>
      </div>
    )
  }

}

export default Navbar
