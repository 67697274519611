const constants = {
    hostName: 'https://alumapower.com/alumapower-api',
    hostNameSection: 'https://alumapower.com/alumapower-api/Section/'
}

// const constants = {
//     hostName: 'https://dev.alumapower.com/alumapower-api',
//     hostNameSection: 'https://dev.alumapower.com/alumapower-api/Section/'
// }

// const constants = {
//     hostName: 'http://localhost:3001/alumapower-api',
//     hostNameSection: 'http://localhost:3001/alumapower-api/Section/'
// }

export default constants;