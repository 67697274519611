import React, { Component } from 'react';
import RobA from '../images/bio-imgs/rob_alexander.jpg';
import HenkV from '../images/bio-imgs/henk_vandenende.jpg';
import GeoffS from '../images/bio-imgs/geoff_sheerin.jpg';
import RichG from '../images/bio-imgs/rich_godwin.jpg';
import PaulS from '../images/bio-imgs/paul_simpson.jpg';

import axios from 'axios';
import localforage from 'localforage';
import constants from 'constants.js';

class Team extends Component {

  constructor(props){
    super(props);

    this.state = {
      people: [
        {
          key: 0,
          id: 0,
          name: '',
          initials: '',
          title: '',
          fullBio: [ '','' ],
          img: ''
        },
        {
          key: 1,
          id: 1,
          name: '',
          initials: '',
          title: '',
          fullBio: [ '','' ],
          img: ''
        }
      ],
      images: [ RobA, HenkV, GeoffS, RichG, PaulS ],
      selected: -1,
      width:  800
    };
    this.renderBio = this.renderBio.bind(this);
    this.handleBioClick = this.handleBioClick.bind(this);
    this.handleBioClose = this.handleBioClose.bind(this);
  }

  componentWillMount(){
     localforage.getItem('token')
      .then( result => {
        const headers = {
          'Content-Type': 'application/json',
          'x-access-token': result
        }
        axios.post( constants.hostNameSection, { title: 'Team' } , {headers: headers})
        .then( res => {
          this.setState({
            people: res.data[5].people[0]
          });
        })
        .catch ( err => {
          console.log(err);
        });  
    });
    
  }

  handleBioClick(event){
    event.preventDefault()
    let bioId = parseInt(event.currentTarget.id, 0);
    if(bioId !== this.state.selected) {
      this.setState(prevState => ({
        selected: bioId
      }))
    } else if (bioId === this.state.selected) {
      this.setState(prevState => ({
        selected: -1
      }))
    }
  }

  handleBioClose(event){
    event.preventDefault()
    this.setState(prevState => ({
      selected: -1
    }))
  }

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    if(window.innerWidth > 900) {
      this.setState({ width: 3});
    } else if(window.innerWidth < 901 && window.innerWidth >= 606) {
      this.setState({ width: 2});
    } else if(window.innerWidth <= 605) {
      this.setState({ width: 1});
    }
  }

  /**
  * Add event listener
  */
 componentDidMount() {
   this.updateDimensions();
   window.addEventListener("resize", this.updateDimensions.bind(this));
 }

 /**
  * Remove event listener
  */
 componentWillUnmount() {
   window.removeEventListener("resize", this.updateDimensions.bind(this));
 }


  renderBio(){
    let arr = [];
    const {selected} = this.state;
    let cols = this.state.width;
    for(let i = 0; i < this.state.people.length + 1; i++){
      let min = (Math.floor(i / cols, 0)) * cols;
      let max = min + (cols - 1);
      let bioContentVisibility = {
        visibility: 'visible',
        opacity: 1,
        transition: 'visibility 0s linear 0s, opacity 300ms'
      };
      if(i === selected) {
        bioContentVisibility = {
          visibility: 'hidden',
          opacity: 0,
          transition: 'visibility 0s linear 300ms, opacity 300ms'
        }
      }
      if(i < this.state.people.length){
        // console.log('image:', this.state.people[i].img);
        let bioContainerBackground = {
          background: `url(${this.state.images[i]}) no-repeat 0 0`,
          backgroundSize: 'cover'
        }
        arr.push(
          <div className="bio-container" key={this.state.people[i].id} id={this.state.people[i].id} style={bioContainerBackground}  onClick={this.handleBioClick} >
            <div className="bio-content" style={bioContentVisibility} >
              <span className="full-name">{this.state.people[i].name}</span>
              <div className="bio-bottom">
                <span className="initials">{this.state.people[i].initials}</span>
                <span className="job-title">{this.state.people[i].title}</span>
              </div>
            </div>
          </div>
        );
      }
      if(i >= 0 && selected >= min && selected <= max && i === max) {
        arr.push(
          <div className="bio-expanded-container" key={`container-${this.state.people[selected].id}`} >
            <div className="home-border-left"></div>
            <span className="bio-close" onClick={this.handleBioClose}>X</span>
            <div className="bio-expanded-content">
              <h2>{this.state.people[selected].name}</h2>
              <h3>{this.state.people[selected].title}</h3>
              <p>{this.state.people[selected].fullBio
                .map((t, index) => <span key={index}>{t}<br/><br/></span>)}
              </p>
            </div>
            <div className="home-border-right"></div>
          </div>
        );
      }
    }
    return arr;
  }

  render() {
    return (
      <div className="container-section" id="container-team">

        <h1 className="header-section" id="header-team">AlumaPower Team</h1>

        <div className="content-section" id="content-team">

          <div>{this.renderBio()}</div>


        </div>
      </div>
    )
  }

}

export default Team
