import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Wrapper from 'Wrapper';
import Homepage from 'Homepage';
import Login from 'Login';
import requireAuthentication from 'Authenticator';

//Changes view served based on url route
const App = () => (
    <div>
        <Switch>
            <Route exact path='/' component={Wrapper} />
            <Route path='/login' component={Login} />
            {/* <Route path='/gamechanger' component={requireAuthentication(Wrapper)} /> */}
        </Switch>
    </div>
);

export default App;