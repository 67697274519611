import React, { Component } from 'react';
import CurvedArrows from '../images/curved_arrows.png';
import Measure from 'react-measure';
import axios from 'axios';
import localforage from 'localforage';
import constants from 'constants.js';

class Solution extends Component {

  constructor(props) {
    super(props);
    this.state = {
      leftVisible: true,
      dimensions: {
        width: -1,
        height: -1
      },
      text: {
        problemTitle: '',
        problem1: '',
        problem2: '',
        problem3: '',
        solutionTitle: '',
        solution1: '',
        solution2: '',
        solution3: ''
      }
    }
  }

  //toggles visibility of carousel problems group
  handleArrowClick(){
    this.setState({
      leftVisible: !this.state.leftVisible,
    });
  }

  componentWillMount(){
    localforage.getItem('token')
      .then( result => {
        const headers = {
          'Content-Type': 'application/json',
          'x-access-token': result
        }
        axios.post( constants.hostNameSection, { title: 'Solution' } , {headers: headers})
        .then( res => {
          this.setState({
            text: {
              problemTitle: res.data[2].text[0],
              problem1: res.data[2].text[1],
              problem2: res.data[2].text[2],
              problem3: res.data[2].text[3],
              solutionTitle: res.data[2].text[4],
              solution1: res.data[2].text[5],
              solution2: res.data[2].text[6],
              solution3: res.data[2].text[7]
            }
          });
        })
        .catch ( err => {
          console.log(err);
        });  
    });
    
    }

  render() {

    //shifts carousel left or right, changes arrow text and flips arrow
    let carouselLeftMargin = "0";
    let slideArrowText = "Our Solution";
    let arrowFlip = "none";
    // const { width, height } = this.state.dimensions;
    let wrapperWidth = this.state.dimensions.width;
    let carouselWidth = (5/3) * wrapperWidth;
    let carouselLgColWidth = -(carouselWidth)*(2/5);
    if (!this.state.leftVisible) {
      carouselLeftMargin = `${carouselLgColWidth}px`;
      slideArrowText = "The Problem";
      arrowFlip = "rotateY(180deg)";
    }
    if (wrapperWidth < 626) {
      carouselWidth = "auto";
    }



    return (
      <div className="container-section" id="container-solution">
        <div className="content-section" id="content-solution">

        <h1>{'Why aren\'t Aluminum-Air Batteries (AL-AB) everywhere?'}</h1>

        <Measure
          bounds
          onResize={(contentRect) => {
           this.setState({ dimensions: contentRect.bounds })
          }}
        >
        {({ measureRef }) =>
            <div id="solution-carousel-wrapper" ref={measureRef}>
            <div className="solution-carousel" style={
                {
                  marginLeft: carouselLeftMargin,
                  width: carouselWidth
                }
              }>

              <div className="flex-col-left flex-col">
                <div className="container-solution-carousel-group">
                  <div>
                    <h2>{this.state.text.problemTitle}</h2>
                    <div className="material-container solution-section-copy">
                      <p>{this.state.text.problem1}</p>
                      <img src={CurvedArrows} alt="" />
                      <p>{this.state.text.problem2}</p>
                      <img src={CurvedArrows} alt="" />
                      <p>{this.state.text.problem3}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-col-middle flex-col">
                <div className="carousel-btns" onClick={this.handleArrowClick.bind(this)}>
                  <p>{slideArrowText}</p>
                  <i className="material-icons" style={{"transform":arrowFlip}}>arrow_forward</i>
                </div>
              </div>

              <div className="flex-col-right flex-col">
                <div className="container-solution-carousel-group">
                  <div>
                    <h2>{this.state.text.solutionTitle}</h2>
                    <div className="material-container solution-section-copy">
                      <p>{this.state.text.solution1}</p>
                      <img src={CurvedArrows} alt="" />
                      <p>{this.state.text.solution2}</p>
                      <img src={CurvedArrows} alt="" />
                      <p>{this.state.text.solution3}</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            </div>
        }

        </Measure>


        </div>
      </div>
    )
  }

}

export default Solution
