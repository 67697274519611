import React, { Component } from 'react';
import Graph from '../images/graph.jpg';
import axios from 'axios';
import localforage from 'localforage';
import constants from 'constants.js';

class Details extends Component {

  constructor(props) {
    super(props);
    this.state = {
      categories: [
        {
          text: ['cat1']
        },
        {
          text: ['cat2']
        }
      ],
      active: 0
    }
    this.handleClick = this.handleClick.bind(this)
  }

  componentWillMount(){ 
    localforage.getItem('token')
      .then( result => {
        const headers = {
          'Content-Type': 'application/json',
          'x-access-token': result
        }
        axios.post( constants.hostNameSection, { title: 'Details' } , {headers: headers})
        .then( res => {
          this.setState({
            categories: res.data[4].categories[0]
          });
        })
        .catch ( err => {
          console.log(err);
        });  
    });
    
  }

  //changes which category is visible
  handleClick(i){
    this.setState({
      active: i
    });

  }

  render() {
    return (
      <div className="container-section" id="container-details">
        <div className="content-section" id="content-details">


          <div className="col-left">
            <ul>
              {this.state.categories.map((category, i) => <li key={i} id={"category" + i} className={ this.state.active===i ? "active" : "inactive" } onClick={() => {this.handleClick(i)}}>{this.state.categories[i].name}</li>)}
            </ul>
          </div>
          <div className="col-right">
            <p>
                {this.state.categories[this.state.active].text
                .map((t, index) => <span key={index}>{t}<br/><br/></span>)}
            </p>
            <div className={ this.state.active===1 ? "container-graph container-graph-show" : "container-graph-hide"}>
              <span>{this.state.categories[1].title}</span>
              <p>{this.state.categories[1].source}</p>
              <img src={Graph} alt="Volumetric Density of Various Materials" />
            </div>
          </div>



        </div>
      </div>
    )
  }

}

export default Details
