import React, { Component } from 'react';
import BatteryImage from '../images/batteries_vector.png';
import DownArrow from '../images/down_arrow.png';
import axios from 'axios';
import localforage from 'localforage';
import constants from 'constants.js';

class Density extends Component {

  constructor(props) {
    super(props);
    this.state = {
      images: '',
      intro: '',
      intro2:'',
      batteryOne: '',
      batteryOneEnergy: '',
      batteryTwo: '',
      batteryTwoEnergy: '',
      batteryTwoDetails: '',
      outro: '',
      outro2: ''
    };
  }

  componentWillMount(){
    localforage.getItem('token')
      .then( result => {
        const headers = {
          'Content-Type': 'application/json',
          'x-access-token': result
        }
        axios.post( constants.hostNameSection, { title: 'Density' } , {headers})
        .then( res => {
          this.setState({
            images: res.data[1].images[0],
            intro: res.data[1].text[0],
            intro2: res.data[1].text[1],
            batteryOne: res.data[1].text[2],
            batteryOneEnergy: res.data[1].text[3],
            batteryTwo: res.data[1].text[4],
            batteryTwoEnergy: res.data[1].text[5],
            batteryTwoDetails: res.data[1].text[6],
            outro: res.data[1].text[7],
            outro2: res.data[1].text[8]
          });
        })
        .catch ( err => {
          console.log(err);
        });  
    });
    
    }

  render() {
    return (
      <div className="container-section" id="container-density">
        <div className="content-section">

          <div className="intro">
            <h1>{this.state.intro}<br/><br/>{this.state.intro2}</h1>
            <img src={DownArrow} alt=""/>
          </div>

          <div className="battery-comparison">
            <div className="battery-comparison-details">
              <p className="battery-type" id="battery-comparison-1">{this.state.batteryOne}</p>
              <div className="divider"></div>
              <p>{this.state.batteryOneEnergy}</p>
            </div>
            <div className="battery-comparison-graphic">
              <img src={BatteryImage} alt=""/>
            </div>
            <div className="battery-comparison-details">
              <p className="battery-type" id="battery-comparison-2">{this.state.batteryTwo}</p>
              <div className="divider"></div>
              <p>{this.state.batteryTwoEnergy}</p>
              <p id="battery-comparison-al-details">{this.state.batteryTwoDetails}</p>
            </div>
          </div>

          <div className="outro">
            <p>{this.state.outro}<br/>{this.state.outro2}</p>
          </div>

        </div>
      </div>
    )
  }

}

export default Density
