import React, { Component } from 'react';
import Logo from '../images/alumapower_logo_white.png';
import WhiteArrow from '../images/white_arrow.png';
import ContactForm from 'ContactForm';


const contactHeaderStyle = {
  fontSize: '2em',
  letterSpacing: '.05em'
}

class Homepage extends Component {

  constructor(props){
    super(props);
    this.state = {
      contactActive: false
    }
    this.toggleContactForm= this.toggleContactForm.bind(this);
  }

  toggleContactForm(){
    this.setState(prevState => ({
      contactActive: !this.state.contactActive
    }));
  }
  

  render() {

    let contactFormStyle = {
      visibility: 'visible',
      opacity: '1',
      position: 'static',
      height: 'auto'
    };
    let contactFormNoStyle = {
    }

    return (
      <div className="container-section" id="container-home">
        <div className="content-section">
          <img src={Logo} id="logo" alt="AlumaPower Coporation"/>
          <div className="text-home">
            <p id="text-splash-intro">
            A development and research group exploring new technologies related to metal air batteries and other novel power generation and storage solutions.
            </p>
            <div className="home-focus">
              <img src={WhiteArrow} alt="" />
              <p style={ contactHeaderStyle } onClick={ this.toggleContactForm }>Contact Us</p>
              <img src={WhiteArrow} id="rt-arrow" alt="" />
            </div>
            <div className='contactHomepageContainer' style={ this.state.contactActive ? contactFormStyle : contactFormNoStyle }><ContactForm styleProps='true' formActive={ this.state.contactActive }/></div>
          </div>
        </div>
      </div>
    )
  }

}

export default Homepage;