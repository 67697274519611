import React, { Component } from 'react';
import axios from 'axios';
import localforage from 'localforage';
import constants from 'constants.js';

class Advantages extends Component {

  constructor(props) {
    super(props);
    this.state = {
      text: {
        adv1: '',
        adv2: '',
        adv3: '',
        adv4: '',
        adv4detail: '',
        appTitle: ''
      },
      applications: ['app1', 'app2']
    }
  }

  componentWillMount(){
    localforage.getItem('token')
      .then( result => {
        const headers = {
          'Content-Type': 'application/json',
          'x-access-token': result
        }
        axios.post( constants.hostNameSection, { title: 'Advantages' } , {headers: headers})
        .then( res => {
          this.setState({
            text: {
            adv1: res.data[3].text[0],
            adv2: res.data[3].text[1],
            adv3: res.data[3].text[2],
            adv4: res.data[3].text[3],
            adv4detail: res.data[3].text[4],
            appTitle: res.data[3].text[5]
          },
          applications: res.data[3].apps[0]
          });
        })
        .catch ( err => {
          console.log(err);
        });  
    });
    
    }

  render() {
    return (
      <div className="container-section" id="container-advantages">

        <h1 className="header-section" id="header-advantages">
          Advantages of the AlumaPower Battery
        </h1>

        <div className="content-section">

          <div className="material-container" id="advantages-material-container">
            <div className="advantage-group">
              <span className="adv-num">1</span>
              <p>{this.state.text.adv1}</p>
            </div>
            <div className="advantage-group">
              <span className="adv-num">2</span>
              <p>{this.state.text.adv2}</p>
            </div>
            <div className="advantage-group">
              <span className="adv-num">3</span>
              <p>{this.state.text.adv3}</p>
            </div>
            <div className="advantage-group">
              <span className="adv-num">4</span>
              <div id="adv-4-text">
                <p id="adv-4">{this.state.text.adv4}</p>
                <p id="asterisk">{this.state.text.adv4detail}</p>
              </div>
            </div>
          </div>

          <div className="material-container applications-group">
            <h3>{this.state.text.appTitle}</h3>
            <div>
              {this.state.applications
                .map((t, index) => <span key={index}>{t}</span>)
                .reduce((prev, curr) => [prev, "+", curr])}
            </div>
          </div>

        </div>
      </div>
    )
  }

}

export default Advantages
