import React, { Component } from 'react';
import axios from 'axios';
import { ReflexProvider, Flex, Box } from 'reflexbox';
// import localforage from 'localforage';
import constants from 'constants.js';

const breakpoints = [ 53 ];


class ContactForm extends Component {

  handleSubmit(e){
        e.preventDefault();
        const name = document.getElementById('name').value;
        const email = document.getElementById('email').value;
        const phone = document.getElementById('phone').value;
        const message = document.getElementById('message').value;
        axios({
            method: "POST",
            url:`${constants.hostName}/send`,
            data: {
                name: name,
                email: email,
                phone: phone,
                message: message
            }
        }).then((response)=>{
            if (response.data.msg === 'success'){
                alert("Message Sent.");
                this.resetForm()
            }else if(response.data.msg === 'fail'){
                alert("Message failed to send.")
            }
        })
    }

    resetForm(){
        document.getElementById('contact-form').reset();
    }

    

  render() {

    const contactHide = {
      display: 'none'
    }

    const contactShow = {
      display: 'block'
    }
    return (
      <div style={ this.props.formActive ? contactShow : contactHide}>

        <form id="contact-form" className={this.props.styleProps ? "contact-form-homepage" : "contact-form-fullSite"} autoComplete="on" onSubmit={this.handleSubmit.bind(this)} method="POST">
          <ReflexProvider breakpoints={breakpoints}>
            <Flex justify="right" align="center" wrap w={1} pt={2} className="reflexboxFlex">
                <Box mb={[2]} w={1}>
                  <Flex align="center" w={1} wrap>
                    <Box w={[1, 1/3]}><label htmlFor="name" name="name">&#42;Name</label></Box>
                    <Box w={[1, 2/3]}><input type="text" id="name" required /></Box>
                  </Flex>
                </Box>

                <Box mb={[2]} w={1}>
                  <Flex align="center" w={1} wrap>
                    <Box w={[1, 1/3]}><label htmlFor="exampleInputEmail1">&#42;Email</label></Box>
                    <Box w={[1, 2/3]}><input type="email" id="email" aria-describedby="emailHelp" name="email" required/></Box>
                  </Flex>
                </Box>

                <Box mb={[2]} w={1}>
                  <Flex align="center" w={1} wrap>
                    <Box w={[1, 1/3]}><label htmlFor="phone">Phone</label></Box>
                    <Box w={[1, 2/3]}><input type="text" id="phone" /></Box>
                  </Flex>
                </Box>

                <Box mb={[2]} w={1}>
                  <Flex align="center" w={1} wrap>
                    <Box w={[1, 1/3]}><label htmlFor="message">&#42;Message</label></Box>
                    <Box w={[1, 2/3]}><textarea rows="5" id="message" required></textarea></Box>
                  </Flex>
                </Box>

            <button type="submit" className="btn-contact-form" style={this.props.btnStyleProps}>Submit</button>
            </Flex>
          </ReflexProvider>
      </form>

      </div>
    )
  }

}

export default ContactForm
