import React, { Component } from 'react';
// import Button from 'Button';
import Logo from '../images/alumapower_logo_white.png';
import WhiteArrow from '../images/white_arrow.png';
import axios from 'axios';
import localforage from 'localforage';
import constants from 'constants.js';


class Splash extends Component {

  constructor(props) {
    super(props);
    this.state = {
      images: '',
      intro: '',
      name: ''
    };
  }

  componentWillMount(){
    localforage.getItem('token')
      .then( result => {
        const headers = {
          'Content-Type': 'application/json',
          'x-access-token': result
        }
        axios.post( constants.hostNameSection, { title: 'Splash' } , {headers: headers})
        .then( res => {
          this.setState({
            images: res.data[0].images,
            intro: res.data[0].text[0],
            name: res.data[0].text[1]
          });
        })
        .catch ( err => {
          console.log(err);
        });  
    });
    
    }

  render() {
    return (
      <div className="container-section" id="container-splash">
        <div className="content-section content-splash">
          <img src={Logo} id="logo" alt="AlumaPower Coporation"/>
          <div className="text-home">
            <p id="text-splash-intro">
             {this.state.intro}
            </p>
            <div className="home-focus">
              <img src={WhiteArrow} alt="" />
              <p>{this.state.name}</p>
              <img src={WhiteArrow} id="rt-arrow" alt="" />
            </div>
          </div>
          <button type="button" onClick={this.props.move}>Let&#x27;s Go</button>
        </div>
      </div>
    )
  }

}

export default Splash
