import React, { Component } from 'react';
import Logo from '../images/alumapower_logo_white.png';
import axios from 'axios';
import localforage from 'localforage';
import constants from 'constants.js';


class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      inputValue: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  handleInput(e){
    this.setState({
      inputValue: e.target.value
    });
  }

  handleSubmit() {
    const headers = {
      'Content-Type': 'application/json'
    }
    axios.post(`${constants.hostName}/authorize`, { password: this.state.inputValue }, {headers})
          .then( response => {
            if( response !== null || undefined )
              {
                localforage.setItem('token', response.data.token)
                  .then(function () {
                    return localforage.getItem('token');
                  })
                  .then( value => {
                    this.props.history.push('/gamechanger');
                  })
                  .catch( err => {});
              } else {
                this.props.history.push('/');
              }
          })
          .catch( error => {
            this.props.history.push('/');
          });
  }

  render() {
    return (
      <div className="container-section" id="container-home">
        <div className="content-section">
          <img src={Logo} id="logo" alt="AlumaPower Coporation"/>
          <div className="login-form">
            <input type="text" name="password" value={this.state.inputValue} onChange={ e => this.handleInput(e) } />
            <button type="submit" onClick={this.handleSubmit}>Submit</button>
          </div>
        </div>
      </div>
    )
  }

}

export default Login;