import React, { Component } from 'react';
import ContactForm from 'ContactForm';
import axios from 'axios';
import localforage from 'localforage';
import constants from 'constants.js';

class Contact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      text: ['', '', '']
    };
  }

  componentWillMount(){
    localforage.getItem('token')
      .then( result => {
        const headers = {
          'Content-Type': 'application/json',
          'x-access-token': result
        }
        axios.post( constants.hostNameSection, { title: 'Contact' } , {headers: headers})
        .then( res => {
          this.setState({
            text: res.data[6].text[0]
          });
        })
        .catch ( err => {
          console.log(err);
        });  
    });
    
    }

  render() {
    return (
      <div className="container-section" id="container-contact">

        <h1 className="header-section" id="header-contact" >Contact Us</h1>

        <div className="content-section">

          <div className="container-contact-email">
            <ContactForm formActive='true' />
          </div>

        </div>
      </div>
    )
  }

}

export default Contact
