import React, { Component } from 'react';
import Navbar from 'Navbar';
import Splash from 'Splash';
import Density from 'Density';
import Solution from 'Solution';
import Advantages from 'Advantages';
import Details from 'Details';
import Team from 'Team';
import Contact from 'Contact';
import scrollToComponent from 'react-scroll-to-component';
import MetaTags from 'react-meta-tags';

class Wrapper extends Component {

  constructor(props){
    super(props);
    this.state = {
      scrollPosition: 'middle'
    }
    this.scrollToTop = this.scrollToTop.bind(this);
    this.scrollToDensity = this.scrollToDensity.bind(this);
    this.scrollToTeam = this.scrollToTeam.bind(this);
    this.scrollToContact = this.scrollToContact.bind(this);
  }

  scrollToTop() {
    scrollToComponent(this.TopSection, { align: this.state.scrollPosition })
  }

  scrollToDensity() {
    scrollToComponent(this.DensitySection, { align: this.state.scrollPosition })
  }

  scrollToTeam() {
    scrollToComponent(this.TeamSection, { align: 'top' })
  }

  scrollToContact() {
    scrollToComponent(this.ContactSection, { align: this.state.scrollPosition })
  }

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    if(window.innerWidth > 600) {
      this.setState({ scrollPosition: 'middle'});
    } else if(window.innerWidth <= 600) {
      this.setState({ scrollPosition: 'top'});
    }
  }

  /**
  * Add event listener
  */
 componentDidMount() {
   this.updateDimensions();
   window.addEventListener("resize", this.updateDimensions.bind(this));
 }

 /**
  * Remove event listener
  */
 componentWillUnmount() {
   window.removeEventListener("resize", this.updateDimensions.bind(this));
 }

  render() {
    return (
      <div id="wrapper">
        <MetaTags>
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        </MetaTags>
        <Navbar top={this.scrollToTop} density={this.scrollToDensity} team={this.scrollToTeam} contact={this.scrollToContact}/>
        <Splash move={this.scrollToDensity} ref={(section) => {this.TopSection = section;}}/>
        <Density ref={(section) => {this.DensitySection = section;}} />
        <Solution />
        <Advantages />
        <Details />
        <Team ref={(section) => {this.TeamSection = section;}} />
        <Contact ref={(section) => {this.ContactSection = section;}} />
      </div>
    )
  }

}

export default Wrapper
